import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import NavBar from "./components/modules/NavBar";
import CssBaseline from "@mui/material/CssBaseline";
import { Route, Routes as Switch, useParams, redirect } from "react-router-dom";
import Home from "./components/pages/HomePage";
import ErrorPage from "./components/pages/ErrorPage";
import Footer from "./components/modules/Footer";
import PageLayout from "./components/pages/PageLayout";
import TermConditions from "./components/pages/t&c" 
import { useSelector, useDispatch } from "react-redux";
import { initial } from "./redux/reducers/home";
import { getUserDetails, logout, setAuthenticate } from "./redux/reducers/users";
import { getUserMenu } from "./redux/reducers/userMenu";

import { getPage } from "./redux/reducers/pages";
import { Skeleton } from "@mui/material";
import axios from "axios";
import jwtDecode from "jwt-decode";
// import { Login } from "@mui/icons-material";
import Dashboard from "./components/dashboard/Dashboard";
import Login from "./components/pages/Login";
import BlogOverview from "./components/Blog/BlogOverview";
import BlogComponent from "./components/Blog/BlogComponent";
import BlogArticle from "./components/Blog/BlogArticle";


const light = {
  palette: {
    mode: "light",
    primary: {
      main: "#5392f0",
      contrastText: "#000000",
    },
    background: {
      paper: "#92c7fb", // your color
      container: "#39c9c4",
    },
    secondary: {
      main: "#676dde",
    },
  },
};

const dark = {
  palette: {
    mode: "dark",
    primary: {
      main: "#5392f0",
    },
    secondary: {
      main: "#676dde",
    },
  },
};

const PageLayoutWrapper = () => {
  const { webPage } = useParams();
  return <PageLayout webpage={webPage} />;
};
let theme 
if (localStorage.getItem("theme")){
  theme = localStorage.getItem("theme")
}

export default function App() {
  const [isDarkTheme, setIsDarkTheme] = React.useState(theme);
  const { pages } = useSelector((state) => state.homePage);
  const { webpages } = useSelector((state) => state.webPages);
  const { authenticated } = useSelector((state) => state.user.authenticated);
  const { userMenu } = useSelector((state) => state.userMenu);
  const loading = useSelector((state) => state.homePage.loading)

  const dispatch = useDispatch();
  const checkToken = () => {
    const token = localStorage.DRToken;

  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      dispatch(logout());
    } else {
      dispatch(setAuthenticate());
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      dispatch(getUserDetails());
      dispatch(getUserMenu() )
    }
  }}
  // const [pages, setPages] = React.useState(null);
  React.useEffect(() => {
    dispatch(initial());
    dispatch(getPage());
    checkToken()
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });
  }, []);

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme)
    localStorage.setItem("theme", isDarkTheme);
  };
  loading && (<><Skeleton width={"80vw"} heigth={"80vh"}  animation="wave"/> </>)
  // {pages &&
  //         webpages &&
  //         pages.map((page) =>
  //           webpages.map((webPage) => {
  //               console.log(page.title, webPage.title)
  //           }
  //         ))}

  return (
    <ThemeProvider theme={isDarkTheme ? createTheme(dark) : createTheme(light)}>
      <CssBaseline />
      {pages && (
        <NavBar pages={pages}>
          <IconButton sx={{ ml: 1 }} onClick={changeTheme} color="inherit">
            {isDarkTheme ? (
              <Brightness3Icon style={{ color: "#eae644" }} />
            ) : (
              <WbSunnyTwoToneIcon style={{ color: "#eae670" }} />
            )}
          </IconButton>
        </NavBar>
      )}
      <Switch>
      <Route path="/" element={<Home />} />
      <Route path="/terms-and-conditions" element={<TermConditions />} />
        {pages &&
          webpages &&
          pages.map((page) =>
            webpages.map((webPage) => {
              if (page.title === webPage.title)
                return (
                  <Route path={`/${page.path}`} element={<PageLayout webpage={webPage} />} />
                );
            })
          )}
        {authenticated && userMenu && (<>
          <Route path={`/dashboard`} element={<PageLayout />} />
          <Route path={`/profile`} element={<PageLayout />} />
          <Route path={`/articles`} element={<PageLayout />} />
          <Route path={`/pageBuilder`} element={<PageLayout />} />

        </>)}
    <Route path="/super-admin-dashboard" element={<Dashboard/>} />

        {/* <Route path="/aviation" element={<Aviation/>} />
        <Route path="/cybersecurity" element={<Security/>} />
        <Route path="/meteorology" element={<Meteorology/>} />
        <Route path="/meteorology/aristotle" element={<Meteorology/>} />
        <Route path="/meteorology/benjamin" element={<Meteorology/>} />
        <Route path="/meteorology/iwxxm-converter" element={<Meteorology/>} /> */}
        <Route path="/blog-content" element={<BlogOverview/>} />
        <Route path="/blog" element={<BlogComponent/>} />
        <Route path="/blog/:id" element={<BlogArticle/>} />
        <Route path="/login_super-admin" element={<Login />} />
        <Route path="*" element={<ErrorPage />} />
      </Switch>
      <Footer />
    </ThemeProvider>
  );
}
