import { keyframes } from "@mui/material";

const zoom = keyframes`
0% {
  background-size: 100% 100%;
}
100% {
  background-size: 110% 110%;
}
`;
const Slider = ({ images, children,height,width }) => (
  <div
    style={{
      backgroundImage: `url("${images}")`,
      // position:'relative',
      width: width? width:"100vw",
      height: height?height:"100vh",
      textAlign: "center",
      backgroundPosition: "center center",
      backgroundRepeat: " no-repeat",
      backgroundAttachment: "fixed",
      backgroundSize: "cover",
      alignItems:"center",
      justifyContent:'center',
      // backgroundColor: '#000',
      // animation: `${zoom} 4s infinite alternate  `
    }}
  >
    {children}
  </div>
);

export default Slider;
