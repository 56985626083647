    import { Box, Container, Typography } from "@mui/material";
    import React from "react";

    const AboutUs = () =>(
        <Box
      component="div"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : theme.palette.grey[900],
        p: 6,
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        textAlign: "center",
        // margin: 10,
        padding: 10,
      }}
    >
      <Container maxWidth="lg">
        <Typography
        variant="h2"
        sx={{ typography: { md: "h2", sm: "h3", xs: "h4" } }}
        >
        Team of Experts
        </Typography>
        <div
        style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
        }}
        >
        <Typography variant="body1">
        At DR Consulting, we pride ourselves on our exceptional team of experts who collectively possess over 15 years of individual experience in our core domains. With a rich history of progress and achievements, our team members bring a wealth of knowledge and expertise to every project we undertake.

    Our highly skilled professionals have dedicated years to honing their craft in the fields of ATC/ATM/AIM services, meteorological services and products, and cybersecurity. Through their extensive hands-on experience, they have encountered diverse challenges, developed innovative solutions, and garnered invaluable insights into the intricacies of our industries.

    Each team member at DR Consulting has a proven track record of success and a passion for excellence. Their deep understanding of the evolving needs and demands of the aviation industry allows us to stay at the forefront of innovation and provide cutting-edge solutions to our clients.

    By combining our collective experience, we are able to offer a comprehensive range of services that cater to the unique requirements of our clients. Whether it's optimizing air traffic control systems, providing accurate meteorological data, or fortifying cybersecurity defenses, our team has the expertise to deliver results that exceed expectations.

    We believe that our success lies in the expertise and dedication of our team members. As lifelong learners, they continuously stay abreast of the latest advancements and industry best practices, ensuring that our clients benefit from the most up-to-date and effective solutions available.

    At DR Consulting, our experienced team is committed to your success. We take pride in our ability to tackle complex challenges, provide customized solutions, and deliver measurable results. With our extensive domain knowledge and commitment to excellence, we are confident in our ability to meet and exceed your expectations.

    Partner with us and experience the difference that our years of individual progress activities and collective expertise can make in elevating your organization to new heights of success in the aviation industry.
        </Typography>
        </div>
    </Container>
    </Box>)


    export default AboutUs