import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from 'axios'
export const getPage = createAsyncThunk('page/getPage', async () => {
    console.log("inital Called");
    const resp = await axios.get(
      `${process.env.REACT_APP_PROXY}/pages`
    );
    const data = await resp.data;
    return data
 
})
export const websitePages = createSlice({
    name: "webPages",
    initialState: {
      webpages: [],
      loading: 'idle',
      error: null,
    },
    reducers: {
      // update: (state,action) => {
      //   console.log("getHomeMenus call"mutating" logic in reducers. It
      //   // doesn't actually mutate the stateed")
      //   // Redux Toolkit allows us to write  because it uses the Immer library,
      //   // which detects changes to a "draft state" and produces a brand new
      //   // immutable state based off those changes.
      //   // Also, no return statement is required from these functions.
      //   fetch(
      //     "http://127.0.0.1:5001/company-website-d2d05/europe-west3/api/webpages"
      //   )
      //     .then((webpages) => webpages.json())
      //     .then((result) => {
      //       result.sort(function (a, b) {
      //         return a.priority - b.priority;
      //       });
      //       console.log("result = ", result);
      //       state.webpages = result;
      //     });
      // },
    },
    extraReducers: (builder) => {
      builder.addCase(getPage.pending, (state, action) => {
        if (state.loading === "idle") {
          state.loading = "pending";
        }
      });
      builder.addCase(getPage.fulfilled, (state, action) => {
        // console.log(state, action);
        if (state.loading === "pending") {
          state.webpages = action.payload;
          state.loading = "idle";
        }
      });
      builder.addCase(getPage.rejected, (state, action) => {
        if (state.loading === "pending") {
          state.loading = "idle";
          state.error = "Error occured";
        }
      });
    },
  });
  
  // export const { update } = websitePages.actions;
  export default websitePages.reducer;