import { Box, Button, Container, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';


export default function ErrorPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid  item={true} xs={6}>
            <Typography variant="h1">
              Ops!!!! Safety Issue!
            </Typography>
            <Typography variant="h6">
              We couldnt find your Flight Plan. Please to land in the alternate airport or go back to the hangar.
            </Typography>
            <Link to="/"> <Button variant="contained">Back Home</Button></Link>
          </Grid>
          <Grid  item={true} xs={6}>
            <img
            title="Zoidberg538, CC BY-SA 4.0 <https://creativecommons.org/licenses/by-sa/4.0>, via Wikimedia Commons"
              src="https://upload.wikimedia.org/wikipedia/commons/2/2a/VRC_showing_LA_center.png"
              alt=""
              width={500} height={300}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
// export default function ErrorPage() {
  
//   return (
//     <div id="error-page">
//           <section class="notFound">
//         <div class="img">
//         <img src="https://assets.codepen.io/5647096/backToTheHomepage.png" alt="Back to the Homepage"/>
//         <img src="https://assets.codepen.io/5647096/Delorean.png" alt="Delorean"/>
//         </div>
//         <div class="text">
//         <h1>404</h1>
//         <h2>PAGE NOT FOUND</h2>
//         <h3>BACK TO HOME?</h3>
//         <a href="/" class="yes">YES</a>
//         <a onclick={nochoice()}>NO</a>
//         </div>
//     </section>
//     </div>
//   );
// }