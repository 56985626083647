import { Button, Container, Dialog, Grid, Slide, AppBar,Toolbar,IconButton, DialogContent,Ty } from "@mui/material";
import React, { useState } from "react";
import NewArticle from "./NewArticle";
import {
    CloseOutlined,
    SendSharp,
  } from "@mui/icons-material";
  import CloseIcon from "@mui/icons-material/Close";
  import Typography from "@mui/material/Typography";


const BlogOverview = () => {
    const [isOpen, setIsOpen] = useState(false)
    
    
    return (
        <Container>
           <Grid>
            <Button variant="contained" onClick={() => setIsOpen(!isOpen)}>NewArticle</Button>
            </Grid> 
            {isOpen && (
                <NewArticle isOpen={isOpen} setIsOpen={setIsOpen}/>
            )}
        </Container>
        
    )
}
export default BlogOverview