import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { v4 as uuid } from "uuid";
import {
  CloseOutlined,
  SendSharp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
// import ErrorPage from '../pages/ErrorPage';
// import Aviation from '../pages/Aviation';
// import Home from '../pages/HomePage';
import Dialog from "@mui/material/Dialog";
// import ListItemText from "@mui/material/ListItemText";
// import ListItem from "@mui/material/ListItem";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  DialogContent,
  Snackbar,
  TextField,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/users";
import axios from "axios";
// import { isMobile } from "react-device-detect";

// const pages = [
//   { title: "Aviation", path: "aviation" },
//   { title: "Cyber Security", path: "cybersecurity" },
//   { title: "Meteorology", path: "meteorology" },
//   { title: "Blog", path: "blog" },
// ];

// const settings = ["Dashboard", "Logout"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//     errorElement: <ErrorPage />,
//   },
//   {
//     path: "aviation",
//     element: <Aviation />,
//   },
// ]);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const uid = uuid();
const geolocationAPI = navigator.geolocation;

// let lat;
// let lon;
let coordinate = {};
// var options = {
//   enableHighAccuracy: true,
//   timeout: 5000,
//   maximumAge: 0,
// };

const getUserCoordinates = async (path) => {
  // if (isMobile) {
  //   // alert(await navigator.permissions.query({
  //   //   name: 'geolocation'
  //   // }))
  //   // await navigator.permissions.query({
  //   //   name: 'geolocation'
  //   // }).then(function(result) {

  //   //   const onLocationFetchSuccess = (position) => {
  //   //     alert(position.coords.latitude)
  //   //     /*
  //   //        Consume location coordinates here and proceed as required
  //   //        using position.coords.latitude and position.coords.longitude
  //   //     */
  //   //   };

  //   //   const onLocationFetchFailure = (error = {}) => {
  //   //     // Error code 1 corresponds to user denying/blocking the location permission
  //   //     alert("ERROR, ", error.code)
  //   //     if (error.code === 1) {
  //   //       // Respond to failure case as required

  //   //     }
  //   //   };

  //   //   navigator.geolocation.getCurrentPosition(onLocationFetchSuccess, onLocationFetchFailure);

  //   //   if (result.state === 'denied') {
  //   //     onLocationFetchFailure();
  //   //   }

  //   //   // This will still work for Chrome
  //   //   result.onchange = function() {
  //   //     if (result.state === 'denied') {
  //   //       onLocationFetchFailure();
  //   //     }
  //   //   }
  //   // })
  //   navigator.permissions.query({ name: "geolocation" }).then((result) => {
  //     /* result.state will be 'granted', 'denied', or 'error' */
  //     if (result.state === "granted") {
  //       if (geolocationAPI) {
  //         geolocationAPI.getCurrentPosition(
  //           (position) => {
  //             console.log(position);
  //             const { coords } = position;
  //             coordinate.latitude = parseFloat(coords.latitude);
  //             coordinate.longitude = parseFloat(coords.longitude);
  //             coordinate.id = uid;
  //             const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate.latitude}&lon=${coordinate.longitude}`;
  //             fetch(url)
  //               .then((res) => res.json())
  //               .then((data) => (coordinate = { ...coordinate, ...data }))
  //               .then(() => {
  //                 fetch("https://geolocation-db.com/json/")
  //                   .then((res) => res.json())
  //                   .then((data) => {
  //                     coordinate.IPv4 = data.IPv4;
  //                     coordinate.page = path;
  //                     axios.post("/logging", coordinate);
  //                   });
  //               });
  //           },
  //           (error) => {
  //             console.log("No geo");
  //           },
  //           options
  //         );
  //       } else {
  //         fetch("https://geolocation-db.com/json/")
  //           .then((res) => res.json())
  //           .then((data) => {
  //             coordinate.id = uid;
  //             coordinate.latitude = data.latitude;
  //             coordinate.longitude = data.longitude;
  //             coordinate.page = window.location.pathname.split("/")[1];
  //             coordinate.address = {
  //               country: data.country_name,
  //               city: data.city,
  //             };
  //             return coordinate;
  //           })
  //           .then((coordinate) => {
  //             axios.post("/logging", coordinate);
  //           })
  //           .catch((err) => {
  //             return;
  //           });
  //       }
  //     }
  //   });
  // } else {
  if (geolocationAPI) {
    geolocationAPI.getCurrentPosition(
      (position) => {
        const { coords } = position;
        coordinate.latitude = parseFloat(coords.latitude);
        coordinate.longitude = parseFloat(coords.longitude);
        coordinate.id = uid;
        const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate.latitude}&lon=${coordinate.longitude}`;
        fetch(url)
          .then((res) => res.json())
          .then((data) => (coordinate = { ...coordinate, ...data }))
          .then(() => {
            fetch("https://geolocation-db.com/json/")
              .then((res) => res.json())
              .then((data) => {
                coordinate.IPv4 = data.IPv4;
                coordinate.page = path;
                axios.post(`${process.env.REACT_APP_PROXY}/logging`, coordinate);
              });
          });
      },
      (error) => {
        // alert(error.code);
        // console.log("No geo");

        fetch("https://geolocation-db.com/json/")
          .then((res) => res.json())
          .then((data) => {
            coordinate.id = uid;
            coordinate.latitude = data.latitude;
            coordinate.IPv4 = data.IPv4;
            coordinate.longitude = data.longitude;
            coordinate.page = window.location.pathname.split("/")[1];
            coordinate.address = {
              country: data.country_name,
              city: data.city,
            };
            return coordinate;
          })
          .then((coordinate) => {
            axios.post(`${process.env.REACT_APP_PROXY}/logging`, coordinate);
          })
          .catch((err) => {
            alert(err)
            return;
          });
      }
    );
  } else {
    fetch("https://geolocation-db.com/json/")
      .then((res) => res.json())
      .then((data) => {
        coordinate.id = uid;
        coordinate.latitude = data.latitude;
        coordinate.longitude = data.longitude;
        coordinate.page = window.location.pathname.split("/")[1];
        coordinate.address = { country: data.country_name, city: data.city };
        return coordinate;
      })
      .then((coordinate) => {
        axios.post(`${process.env.REACT_APP_PROXY}/logging`, coordinate);
      })
      .catch((err) => {
        return;
      });
  }
  // }
};

getUserCoordinates(window.location.pathname.split("/")[1].length?window.location.pathname.split("/")[1]:'home');

function NavBar({ children }) {
  const [lat, setLat] = React.useState(null);
  const [long, setLong] = React.useState(null);
  // console.log('pages=', pages)
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [menuIcon, setMenuIcon] = React.useState(true);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackError, setSnackError] = React.useState(null);
  const pages = useSelector((state) => state.homePage.pages);
  const { authenticated, user } = useSelector((state) => state.user);
  const { userMenu } = useSelector((state) => state.userMenu);

  // console.log("pages navbar = ",pages )
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(initial())
  // }, [dispatch]);
  const [errors, setErrors] = React.useState({
    company: null,
    from_email: null,
    message: null,
    from_name: null,
    from_lastname: null,
    password: null,
    confirmPassword: null,
  });
  // const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState({
    company: null,
    from_email: null,
    message: null,
    from_name: null,
  });
  const [open, setOpen] = React.useState(false);
  const [openSignin, setOpenSignin] = React.useState(false);
  const [registerLogin, setRegisterLogin] = React.useState("Login");
  const [signinForm, setSigninForm] = React.useState({});
  const [registerForm, setRegisterForm] = React.useState({});
  const [loginRegister, setLoginRegister] = React.useState({
    email: null,
    password: null,
    passwordConfirm: null,
    firstName: null,
    lastName: null,
  });
  React.useEffect(() => {
    // console.log(authenticated);
    if (authenticated) {
      setOpenSignin(false);
    }
  }, []);
  const sendMessage = (e) => {
    e.preventDefault();
    const { from_name, message, from_email, company } = email;
    let er = null;
    if (
      !errors.company &&
      !errors.from_email &&
      !errors.from_name &&
      !errors.message
    ) {
      emailjs
        .send(
          process.env.REACT_APP_SNAME,
          process.env.REACT_APP_TEMPLATE,
          {
            from_name,
            message,
            from_email,
            company,
          },
          process.env.REACT_APP_PKEY
        )
        .then((result) => {
          if (result.status !== 200) {
            setSnackError(true);
          }
          setSnackOpen(true);
        });
    }
  };

  const validateEmail = (e) => {
    const error = {};

    if (!e.target.value) {
      error.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      error.email = "Invalid email address";
    }
    if (error.email) {
      setErrors({ ...errors, from_email: error.email });
    } else {
      setErrors({ ...errors, from_email: null });
      if (e.target.id === "from_email")
        setEmail({ ...email, from_email: e.target.value });
    }
  };
  const validateName = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Full Name  Required";
    } else if (e.target.value.split(" ").length <= 1) {
      error.name = "Invalid Full Name";
    }
    if (error.name) {
      setErrors({ ...errors, from_name: error.name });
    } else {
      setErrors({ ...errors, from_name: null });
      setEmail({ ...email, from_name: e.target.value });
    }
  };
  const validateMessage = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Message Required";
    } else if (e.target.value.length <= 10) {
      error.name = "Invalid Message";
    }
    if (error.name) {
      setErrors({ ...errors, message: error.name });
    } else {
      setErrors({ ...errors, message: null });
      let p = e.target.value;
      let message = p;
      if (/<|>|;|\(/g.test(p)) {
        message = p.replace(/<|>|;|\(/g, " __");
      }
      setEmail({ ...email, message });
    }
  };
  const validateCompany = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Company  Required";
    } else if (e.target.value.length < 3) {
      error.name = "Invalid Company";
    }
    if (error.name) {
      setErrors({ ...errors, company: error.name });
    } else {
      setErrors({ ...errors, company: null });
      setEmail({ ...email, company: e.target.value });
    }
  };

  const handleCloseSnack = (event, reason) => {
    setSnackOpen(false);
    setSnackError(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSignin = () => {
    setOpenSignin(false);
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
    setMenuIcon(!menuIcon);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    setMenuIcon(!menuIcon);
    // if (page) {
    //   fetch("https://geolocation-db.com/json/")
    //     .then((res) => res.json())
    //     .then((data) => {
    //       data.id = uid
    //       data.page = page;
    //       axios.post("/logging", data);
    //     })
    //     .then((res) => {
    //       return;
    //     })
    //     .catch((err) => {
    //       return;
    //     });
    // }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar position="relative" color="primary">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Button
              sx={{ display: { xs: "none", md: "flex" }, mr: 0, ml: 5 }}
              onClick={() => getUserCoordinates("home")}
            >
              <Link to="/">
                <img src="img/DRC.ico" aria-label="Logo" width={60} />
              </Link>
            </Button>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                {menuIcon ? <MenuIcon /> : <CloseOutlined />}
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem style={{ textDecoration: "none", color: "inherit" }}>
                  <Link
                    // component="button"
                    // variant="body2"
                    onClick={() => {
                      getUserCoordinates("home");
                    }}
                    to="/"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {"Home"}
                  </Link>
                </MenuItem>

                {pages.map((page) => (
                  <MenuItem
                    key={page.title}
                    onClick={() => {
                      getUserCoordinates(page.path);
                    }}
                  >
                    <Link
                      // component="button"
                      // variant="body2"
                      // onClick={() => {
                      //   console.info("I'm a button.");
                      // }}

                      to={page.path}
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        getUserCoordinates(page.path);
                      }}
                    >
                      {page.title}
                    </Link>
                    {/* <Typography textAlign="center" >{page}</Typography> */}
                  </MenuItem>
                ))}
                <MenuItem
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={() => {
                    getUserCoordinates("blog");
                  }}
                >
                 <Link
                      // component="button"
                      // variant="body2"
                      // onClick={() => {
                      //   console.info("I'm a button.");
                      // }}

                      to="blog"
                      style={{ textDecoration: "none", color: "inherit" }}
                      onClick={() => {
                        getUserCoordinates("blog");
                      }}
                    >
                      Blog
                    </Link>
                </MenuItem>
                <MenuItem
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={() => {
                    getUserCoordinates("contat-us");
                    handleClickOpen();
                  }}
                >
                  {"Contact Us"}
                </MenuItem>
              </Menu>
            </Box>
            <Button
              sx={{
                // mr: 1,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                justifyContent: "center",
                width: "80vw",
              }}
            >
              <img src="img/DRC.ico" aria-label="Logo" height={50} />
            </Button>
            {/* <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography> */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.title}
                  // href={page.path}
                  sx={{ my: 2, color: "white", display: "block" }}
                  onClick={() => {
                    getUserCoordinates(page.path);
                  }}
                >
                  <Link
                    to={page.path}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {page.title}
                  </Link>
                </Button>
              ))}
              <Button
                  key="blog"
                  // href={page.path}
                  sx={{ my: 2, color: "white", display: "block" }}
                  onClick={() => {
                    getUserCoordinates("blog");
                  }}
                >
                  <Link
                    to={'blog'}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Blog
                  </Link>
                </Button>
              <Button
                sx={{ my: 2, color: "white", display: "block" }}
                onClick={() => {
                  handleClickOpen();
                  getUserCoordinates("contact-us");
                }}
              >
                Contact Us
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {authenticated && (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ ml: 1 }}>
                      <Avatar
                        alt={`${user.firstName} ${user.lastName}`}
                        src={user.profilePic}
                      />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem
                      key="Dashboard"
                      onClick={(e) => {
                        handleCloseUserMenu();
                      }}
                    >
                      <Link
                        to="/super-admin-dashboard"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography textAlign="center">Dashboard</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      key="Blog"
                      onClick={(e) => {
                        handleCloseUserMenu();
                      }}
                    >
                      <Link
                        to="/blog-content"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Typography textAlign="center">Blog</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      key="Logout"
                      onClick={(e) => {
                        dispatch(logout());
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    {/* {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        onClick={(e) => {
                          if (setting === "Logout") {
                            dispatch(logout());
                          } else {
                            return (
                              <Navigate replace to="/super-admin-dashboard" />
                            );
                          }

                          handleCloseUserMenu();
                        }}
                      >
                        <Typography textAlign="center">{setting}</Typography>
                      </MenuItem>
                    ))} */}
                  </Menu>
                </>
              )}
              {/* {!authenticated && (
                <IconButton
                  sx={{ ml: 1 }}
                  style={{ textDecoration: "none", color: "white" }}
                  size="small"
                  onClick={() => setOpenSignin(true)}
                >
                  <Login  />
                </IconButton>
              )} */}
            </Box>
            {children}
          </Toolbar>
        </Container>
      </AppBar>
      {/* CONTACT FORM */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} color="primary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              color="white"
            >
              Contact Us
            </Typography>
            <Button
              style={{ color: "white" }}
              variant="outlined"
              // aria-label="account of current user"
              // aria-controls="menu-appbar"
              // aria-haspopup="true"
              onClick={sendMessage}
            >
              <SendSharp style={{ marginRight: 10 }} /> Send Message
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            error={errors.from_email ? true : false}
            autoFocus
            margin="dense"
            id="from_email"
            label={errors.from_email ? errors.from_email : "Email Address"}
            type="email"
            fullWidth
            variant="standard"
            onChange={validateEmail}
          />
          <TextField
            error={errors.from_name ? true : false}
            autoFocus
            margin="dense"
            id="from_name"
            label={errors.from_name ? errors.from_name : "Full Name"}
            type="text"
            fullWidth
            variant="standard"
            onChange={validateName}
          />
          <TextField
            error={errors.company ? true : false}
            autoFocus
            margin="dense"
            id="company"
            label={errors.company ? errors.company : "Company"}
            type="text"
            fullWidth
            variant="standard"
            onChange={validateCompany}
          />
          <TextField
            error={errors.message ? true : false}
            autoFocus
            margin="dense"
            id="message"
            label={errors.message ? errors.message : "Message"}
            type="text"
            fullWidth
            multiline
            variant="standard"
            rows={4}
            onChange={validateMessage}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackError
            ? "An error occurred! Try again or reach out to us at info@davideraroconsulting.com"
            : `Thanks for reaching out to us ${email.from_name}! Our Team will get back to you soon!`}
        </Alert>
      </Snackbar>
      {/* <Dialog
        fullScreen
        open={openSignin}
        onClose={handleCloseSignin}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }} color="secondary">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseSignin}
              aria-label="close"
            >
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
              color="white"
            >
              {registerLogin}
            </Typography>
            <Button
              style={{ color: "white" }}
              variant="outlined"
              // aria-label="account of current user"
              // aria-controls="menu-appbar"
              // aria-haspopup="true"
              onClick={registerLogin === "Login"? signin:signup}
            >
               {registerLogin === "Login"? <LoginSharp style={{ marginRight: 10 }} /> :<LoginSharp style={{ marginRight: 10 }} />}
               {registerLogin === "Login"?"Login":"Register"}
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            error={loginRegister.email ? true : false}
            autoFocus
            margin="dense"
            id="email"
            label={errors.from_email ? errors.from_email : "Email Address"}
            type="email"
            fullWidth
            variant="standard"
            onChange={validateEmail}
          />
          <TextField
            error={errors.password ? true : false}
            autoFocus
            margin="dense"
            id="password"
            label={errors.password ? errors.password : "Full Name"}
            type="password"
            fullWidth
            variant="standard"
            onChange={validateName}
          />
          {registerLogin !== 'Login' && (<>
          <TextField
            error={errors.passwordConfirm ? true : false}
            autoFocus
            margin="dense"
            id="passwordConfirm"
            label={errors.passwordConfirm ? errors.passwordConfirm : "Password"}
            type="text"
            fullWidth
            variant="standard"
            onChange={validatePassword}
          />
          <TextField
            error={errors.message ? true : false}
            autoFocus
            margin="dense"
            id="first"
            label={errors.message ? errors.message : "Message"}
            type="text"
            fullWidth
            multiline
            variant="standard"
            rows={4}
            onChange={validateMessage}
          /></>)}
{          registerLogin === 'Login'&&( <Typography onClick={() => setRegisterLogin("Register")}>Don't you have an account? Register</Typography>)
}          {registerLogin === 'Register'&&( <Typography onClick={() => setRegisterLogin("Login")}>Already registered? Sign in here!</Typography>)}

        </DialogContent>
      </Dialog> */}
    </>
  );
}
export default NavBar;
