import axios from 'axios'
import { createAsyncThunk, createSlice, rejectWithValue } from "@reduxjs/toolkit";


const setAuthorizationHeader = (token) => {
    const FBIdToken = `${token}`;
    localStorage.setItem("DRToken", FBIdToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${FBIdToken}`;
  };
export const login = createAsyncThunk("user/login", async (userData, rejectWithValue) => {
    // console.log("userData = ", userData)
    try {
       const res = await axios.post(`${process.env.REACT_APP_PROXY}/login`, userData)
       const token = res.data.token
       setAuthorizationHeader(token)
       return token
    } catch (error) {
      console.log("error = ", error)
      return rejectWithValue(error.response.data.errors)
    }
    
});
export const getUserDetails = createAsyncThunk("user/userDetails", async () => {
    const resp = await axios.get(`${process.env.REACT_APP_PROXY}/user` )
    
    const user = await resp.data
    // console.log(user)
    return user
});
// export const logoutAction = createAsyncThunk("user/logout",async (id) =>{
//     localStorage.removeItem("DRToken");
//     delete axios.defaults.headers.common["Authorization"];
    
// })
export const userActions = createSlice({
  name: "user",
  initialState: {
    user: {},
    authenticated: false,
    error: null,
    loading:false
  },
  reducers: {
    logout: (state, action) => {
        localStorage.removeItem("DRToken");
        delete axios.defaults.headers.common["Authorization"];
        state.authenticated = false
        
    },
    setAuthenticate: (state,action) => {
        state.authenticated = true
    }
  },
  extraReducers:(builder) => {
    builder.addCase(login.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (state.loading) {
        state.authenticated = true;
        state.loading = false;
      }
    });
    
    builder.addCase(login.rejected, (state, action) => {
      console.log(action)
        state.loading = false;
        localStorage.removeItem("DRToken");
        delete axios.defaults.headers.common["Authorization"];
        state.error = "Something went wrong";
      
    });
    builder.addCase(getUserDetails.pending, (state, action) => {
        if (!state.loading) {
          state.loading = true;
        }
      });
      builder.addCase(getUserDetails.fulfilled, (state, action) => {
        if (state.loading) {
            // console.log("action.payload = ", action.payload)
          state.user = action.payload;
          state.loading = false;
        }
      });
      builder.addCase(getUserDetails.rejected, (state, action) => {
        if (state.loading) {
          state.loading = false;
          state.error = "Error occured";
        }
      });
  },
});

export const { logout,setAuthenticate } = userActions.actions;
export default userActions.reducer;
