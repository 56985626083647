import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, LinkedIn, SendSharp, YouTube } from "@mui/icons-material";
import { Alert, Box, Button, Snackbar, TextField } from "@mui/material";
import emailjs from "@emailjs/browser";


export default function Footer() {
  const [errors, setErrors] = React.useState({
    company: null,
    from_email: null,
    message: null,
    from_name: null,
    from_lastname: null,
    password: null,
    confirmPassword: null,
  });
  // const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [email, setEmail] = React.useState({
    company: null,
    from_email: null,
    message: null,
    from_name: null,
  });
  const [snackError, setSnackError] = React.useState(null);
  const [snackOpen, setSnackOpen] = React.useState(false);

  const sendMessage = (e) => {
    e.preventDefault();
    const { from_name, message, from_email, company } = email;
    let er = null;
    if (
      !errors.company &&
      !errors.from_email &&
      !errors.from_name &&
      !errors.message
    ) {
      emailjs
        .send(
          process.env.REACT_APP_SNAME,
          process.env.REACT_APP_TEMPLATE,
          {
            from_name,
            message,
            from_email,
            company,
          },
          process.env.REACT_APP_PKEY
        )
        .then((result) => {
          if (result.status !== 200) {
            setSnackError(true);
          }
          setSnackOpen(true);
          setEmail({
            company: null,
            from_email: null,
            message: null,
            from_name: null,
          })
        });
    }
  };

  const validateEmail = (e) => {
    const error = {};

    if (!e.target.value) {
      error.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)
    ) {
      error.email = "Invalid email address";
    }
    if (error.email) {
      setErrors({ ...errors, from_email: error.email });
    } else {
      setErrors({ ...errors, from_email: null });
      if (e.target.id === "from_email")
        setEmail({ ...email, from_email: e.target.value });
    }
  };
  const validateName = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Full Name  Required";
    } else if (e.target.value.split(" ").length <= 1) {
      error.name = "Invalid Full Name";
    }
    if (error.name) {
      setErrors({ ...errors, from_name: error.name });
    } else {
      setErrors({ ...errors, from_name: null });
      setEmail({ ...email, from_name: e.target.value });
    }
  };
  const validateMessage = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Message Required";
    } else if (e.target.value.length <= 10) {
      error.name = "Invalid Message";
    }
    if (error.name) {
      setErrors({ ...errors, message: error.name });
    } else {
      setErrors({ ...errors, message: null });
      let p = e.target.value;
      let message = p;
      if (/<|>|;|\(/g.test(p)) {
        message = p.replace(/<|>|;|\(/g, " __");
      }
      setEmail({ ...email, message });
    }
  };
  const validateCompany = (e) => {
    const error = {};

    if (!e.target.value) {
      error.name = "Company  Required";
    } else if (e.target.value.length < 3) {
      error.name = "Invalid Company";
    }
    if (error.name) {
      setErrors({ ...errors, company: error.name });
    } else {
      setErrors({ ...errors, company: null });
      setEmail({ ...email, company: e.target.value });
    }
  };
  const handleCloseSnack = (event, reason) => {
    setSnackOpen(false);
    setEmail({
      company: null,
      from_email: null,
      message: null,
      from_name: null,
    })
    setSnackError(null);
  };
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[800],
        p: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              About Us
            </Typography>
            <Typography variant="body2" color="text.secondary">
              We are a team of experts that support private companies and gouvernmental offices in the Aviation and Meteorological domains. 
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Contact Us
            </Typography>
            <TextField
            error={errors.from_email ? true : false}
            autoFocus
            margin="dense"
            id="from_email"
            label={errors.from_email ? errors.from_email : "Email Address"}
            type="email"
            fullWidth
            variant="standard"
            onChange={validateEmail}
          />
          <TextField
            error={errors.from_name ? true : false}
            autoFocus
            margin="dense"
            id="from_name"
            label={errors.from_name ? errors.from_name : "Full Name"}
            type="text"
            fullWidth
            variant="standard"
            onChange={validateName}
          />
          <TextField
            error={errors.company ? true : false}
            autoFocus
            margin="dense"
            id="company"
            label={errors.company ? errors.company : "Company"}
            type="text"
            fullWidth
            variant="standard"
            onChange={validateCompany}
          />
          <TextField
            error={errors.message ? true : false}
            autoFocus
            margin="dense"
            id="message"
            label={errors.message ? errors.message : "Message"}
            type="text"
            fullWidth
            multiline
            variant="standard"
            rows={4}
            onChange={validateMessage}
          />
          <Button
              style={{ color: "white" }}
              variant="outlined"
              // aria-label="account of current user"
              // aria-controls="menu-appbar"
              // aria-haspopup="true"
              onClick={sendMessage}
            >
              <SendSharp style={{ marginRight: 10 }} /> Send Message
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Link href="https://www.facebook.com/davideraroconsulting" color="inherit" target="_blank">
              <Facebook />
            </Link>
            <Link
              href="https://www.linkedin.com/company/davide-raro-consulting" target="_blank"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <LinkedIn />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UCZl2pC0VPXTOfj39Yla02Tw" target="_blank"
              color="inherit"
              sx={{ pl: 1, pr: 1 }}
            >
              <YouTube />
            </Link>
          </Grid>
        </Grid>
        <Box></Box>
        <br></br>
        <Grid container>
        <Grid item xs={12} sm={4}>
          
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://davideraroconsulting.com/" >
              Davide Raro Consulting Limited
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
          <Typography variant="body2" color="text.secondary" align="center">
            <Link color="inherit" href="/terms-and-conditions" >
              Term and Conditions
            </Link>            {"."}
          </Typography>
        </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackError ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          {snackError
            ? "An error occurred! Try again or reach out to us at info@davideraroconsulting.com"
            : `Thanks for reaching out to us ${email.from_name}! Our Team will get back to you soon!`}
        </Alert>
      </Snackbar>
    </Box>
  );
}