import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"
export const initial = createAsyncThunk("homePage/menuItems", async () => {
  // console.log("inital Called");
  const resp = await axios.get(
    `${process.env.REACT_APP_PROXY}/menu`
  );
  const data = await resp.data;
  data.sort(function (a, b) {
    return a.priority - b.priority;
  });
  let datas = data.filter(function( obj ) {
    return obj.title !== "Blog";
  });
  let blog = data.filter(function( obj ) {
    return obj.title === "Blog";
  });
  return {datas,blog};
});
export const homePageMenu = createSlice({
  name: "homePage",
  initialState: {
    pages: [],
    blog:{},
    loading: false,
    error: null,
  },
  reducers: {
    // update: (state,action) => {
    //   console.log("getHomeMenus call"mutating" logic in reducers. It
    //   // doesn't actually mutate the stateed")
    //   // Redux Toolkit allows us to write  because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes.
    //   // Also, no return statement is required from these functions.
    //   fetch(
    //     "http://127.0.0.1:5001/company-website-d2d05/europe-west3/api/pages"
    //   )
    //     .then((pages) => pages.json())
    //     .then((result) => {
    //       result.sort(function (a, b) {
    //         return a.priority - b.priority;
    //       });
    //       console.log("result = ", result);
    //       state.pages = result;
    //     });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(initial.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    });
    builder.addCase(initial.fulfilled, (state, action) => {
      // console.log(state, action);
      if (state.loading) {
        state.pages = action.payload.datas;
        state.blog = action.payload.blog
        state.loading = false;
      }
    });
    builder.addCase(initial.rejected, (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

// export const { update } = homePageMenu.actions;
export default homePageMenu.reducer;
