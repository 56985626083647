import React from "react";
import Slider from "../modules/Slider";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import StyleSubtitle from "../modules/styledSubtitles";
export default class PageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle: this.props.webpage.blinking[0],
      x: 0,
      webpage: this.props.webpage,
      analytics: false,
      changeSubtitles: () => {
        setInterval(() => {
          if (this.state.x < this.props.webpage.blinking.length) {
            this.setState({ x: this.state.x });
            this.setState({
              subtitle: this.props.webpage.blinking[this.state.x],
            });
            this.setState({ x: this.state.x + 1 });
          } else if (this.state.x === this.props.webpage.blinking.length) {
            this.setState({ x: 0 });
            this.setState({ subtitle: this.props.webpage.blinking[0] });
          } else {
            this.setState({ x: 0 });
            this.setState({ subtitle: this.props.webpage.blinking[0] });
          }
        }, 4000);
      },
    };
  }

  componentDidMount() {
    // this.setState({subtitle :aviationPage[0]})
    this.state.changeSubtitles();
    // setTimeout(() => {
    //   if (!this.state.analytics) {
    //     fetch("https://geolocation-db.com/json/")
    //       .then((res) => res.json())
    //       .then((data) => axios.post("/logging", data))
    //       .then((res) => this.setState({ analytics: true }));
    //   }  
    // }, 3000)
  }
   
  componentWillUnmount() {
    // console.log("componentwillunmount");
    clearInterval(this.state.changeSubtitles);
    this.setState({ analytics: false });
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.webpage !== prevProps.webpage) {
      this.setState({ webpage: this.props.webpage, subtitle: "", x: 0 });
      clearInterval(this.state.changeSubtitles);
      //    if (!this.state.analytics) {
      //   fetch("https://geolocation-db.com/json/")
      //     .then((res) => res.json())
      //     .then((data) => axios.post("/logging", data))
      //     .then((res) => this.setState({ analytics: true }));
      // }  
      // this.state.changeSubtitles()
    }
  }

  //     if (!this.state.analytics) {
  // fetch("https://geolocation-db.com/json/")
  //         .then((res) => res.json())
  //         .then((data) => axios.post("/logging", data))
  //         .then((res) => this.setState({ analytics: true }));    }
  //   }
  render() {
    return (
      <div>
        <Slider images={this.state.webpage.bgImage}>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0, 0.8)",
              // top: 0,
              // left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                backgroundColor: "rgba(0,0,0,.5)",
                justifyContent: "center",
              }}
            >
              {/* <div style={{ width: "50%" }}></div> */}
              <div
                style={{
                  margin: 10,
                  padding: 10,
                  minHeight: "97vh",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{ typography: { md: "h2", sm: "h3", xs: "h3" } }}
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  {this.state.webpage.title}
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  {this.state.webpage.subtitle}
                </Typography>
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div>
                    <StyleSubtitle style={{ color: "white" }}>
                      {this.state.subtitle}
                    </StyleSubtitle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        {this.state.webpage.sections.map((section) => (
          <Box
            key={section.sectionName}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              // margin: 10,            // padding: 10,
            }}
            sx={
              section.sectionColor !== "primary"
                ? {
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.background.paper
                        : theme.palette.grey[900],
                    p: 6,
                  }
                : {}
            }
          >
            <Typography
              variant="h2"
              sx={{ typography: { md: "h2", sm: "h3", xs: "h4" } }}
            >
              {section.sectionName}
            </Typography>
            <Typography
              variant="body1"
              // sx={{ typography: { md: "h2", sm: "h3", xs: "h4" } }}
            >
              {section.sectionDescription}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {section.serviceList &&
                section.serviceList.map((service) => (
                  <Card
                    elevation={16}
                    color="secondary"
                    sx={{ maxWidth: 330 }}
                    key={service.serviceName}
                    style={{ margin: 15, padding: 15 }}
                  >
                    <CardMedia
                      sx={{
                        display: { md: "flex", sm: "flex", xs: "flex" },
                        height: { md: 100, sm: 50, xs: 35 },
                      }}
                      component="img"
                      alt="aviation"
                      height="100"
                      image={service.serviceIcon}
                      style={{ objectFit: "contain" }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {service.serviceName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {service.serviceDescription}
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
            </div>
          </Box>
        ))}
      </div>
    );
  }
}
