import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios"
export const getUserMenu = createAsyncThunk("userMenu/menuItems", async () => {
  const resp = await axios.get(
    `${process.env.REACT_APP_PROXY}/userMenu`
  );
  const data = await resp.data;
  // console.log(resp)
  data.sort(function (a, b) {
    return a.priority - b.priority;
  });
  return data;
});
export const userMenu = createSlice({
  name: "userMenu",
  initialState: {
    userMenu: [],
    loading: false,
    error: null,
  },
  reducers: {
    // update: (state,action) => {
    //   console.log("getHomeMenus call"mutating" logic in reducers. It
    //   // doesn't actually mutate the stateed")
    //   // Redux Toolkit allows us to write  because it uses the Immer library,
    //   // which detects changes to a "draft state" and produces a brand new
    //   // immutable state based off those changes.
    //   // Also, no return statement is required from these functions.
    //   fetch(
    //     "http://127.0.0.1:5001/company-website-d2d05/europe-west3/api/pages"
    //   )
    //     .then((pages) => pages.json())
    //     .then((result) => {
    //       result.sort(function (a, b) {
    //         return a.priority - b.priority;
    //       });
    //       console.log("result = ", result);
    //       state.pages = result;
    //     });
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserMenu.pending, (state, action) => {
      if (!state.loading) {
        state.loading = true;
      }
    });
    builder.addCase(getUserMenu.fulfilled, (state, action) => {
      // console.log(state, action);
      if (state.loading) {
        state.userMenu = action.payload;
        state.loading = false;
      }
    });
    builder.addCase(getUserMenu.rejected, (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.error = "Error occured";
      }
    });
  },
});

// export const { update } = homePageMenu.actions;
export default userMenu.reducer;
