const { styled ,keyframes} = require("@mui/system");

const typing = keyframes`
0% { width: 0 }
  80% { width: 100% }
  90%, 100% { width: 0 }
`;
const blinkcaret = keyframes`
from, to { border-color: transparent; }
    50% { border-color: orange; }`;

// const t = keyframes`
// from { width: 100% }
//     to { width: 0;}`;
// const blinkWait = keyframes`
// from, to { width: 0 }
//     30%, 60% { width: 45px }
// `;
// @keyframes typing {
//     from { width: 0; visibility: hidden; }
//     to { width: 100%; visibility: visible; color: #000; }
// }

// @keyframes typingReverse {
//     from { width: 100%; visibility: visible; color: #000; }
//     to { width: 0; }
// }

// @keyframes blink-wait {
//     from, to { width: 0; visibility: hidden; }
//     30%, 60% { width: 45px; visibility: visible; }
// }

// @keyframes blink-caret {
//     from, to { border-color: transparent; }
//     50% { border-color: #999; }
// }

const StyleSubtitle = styled("h2")({
  overflow: "hidden",
  //   maxWidth:250,
  //   height:'50px',
  width: "fit-content",
  //   minWidth:100 /* Ensures the content is not revealed until the animation */,
  borderRight: ".15em solid orange" /* The typwriter cursor */,
  whiteSpace: "nowrap" /* Keeps the content on a single line */,
  margin: "0 0" /* Gives that scrolling effect as the typing happens */,
  letterSpacing: ".15em" /* Adjust as needed */,
  animation: `${typing} 4s steps(20) infinite ,
  ${blinkcaret} .4s step-end infinite;`,
  //   `${typing}   1s steps(22, end)  , ${blinkcaret} .5s step-end infinite alternate, t calc(var(--n)*.3s) steps(var(--n)) forwards`
  // `${typing} 4s steps(15,end) alternate ,${blinkcaret} 0.8s  infinite ,   ${blinkWait} .5s , ${t} 4s steps(15)   infinite;`,
  /* The typing effect */
});

export default StyleSubtitle