import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Skeleton,
  Typography,
} from "@mui/material";
import Slider from "../modules/Slider";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";

const LoadingCard = ({ key }) => (
  <div style={{ width: 300 }}>
    <Card
      elevation={16}
      color="secondary"
      sx={{ maxWidth: 330 }}
      key={key}
      style={{ margin: 15, padding: 15 }}
    >
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      <CardContent>
        <React.Fragment>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </React.Fragment>
      </CardContent>
    </Card>
  </div>
);

export default class BlogComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      error: null,
      loading: true,
    };
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_PROXY}/blog-articles`)
      .then((resp) => this.setState({ articles: resp.data, loading: false }))
      .catch((err) =>
        this.setState({ error: "Unable to load the page", loading: false })
      );
  }
  componentWillUnmount() {}
  componentDidUpdate(prevProps) {}
  render() {
    return (
      <div>
        <Slider
          images={
            "https://images.unsplash.com/photo-1464802686167-b939a6910659?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2900&q=80"
          }
        >
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0, 0.8)",
              // top: 0,
              // left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                backgroundColor: "rgba(0,0,0,.5)",
                justifyContent: "center",
              }}
            >
              {/* <div style={{ width: "50%" }}></div> */}
              <div
                style={{
                  margin: 10,
                  padding: 10,
                  minHeight: "97vh",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{ typography: { md: "h2", sm: "h3", xs: "h3" } }}
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  Welcome to our blog
                </Typography>
                {/* <Typography
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  {this.state.webpage.subtitle}
                </Typography> */}
                {/* <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div>
                    <StyleSubtitle style={{ color: "white" }}>
                      {this.state.subtitle}
                    </StyleSubtitle>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </Slider>
        <Box
          key="blog-cared"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
            width: "100%",
            textAlign: "center",
            // margin: 10,            // padding: 10,
          }}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.background.paper
                : theme.palette.grey[900],
            p: 6,
          }}
        >
          {this.state.loading && (
            <>
              <LoadingCard key={"1"} />
              <LoadingCard key={"2"} />
              <LoadingCard key={"3"} />
            </>
          )}
          {this.state.articles &&
            this.state.articles.map((service) => (
              <Card
                elevation={16}
                color="secondary"
                sx={{ maxWidth: 330 }}
                key={service.id}
                style={{ margin: 15, padding: 15 }}
              >
                <CardHeader
                  title={
                    <Typography variant="body1" color="text.secondary">
                      {service.title}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component={"p"}
                    >
                      {new Date(service.createdAt).toLocaleString()}
                    </Typography>
                  }
                />

                <CardMedia
                  sx={{
                    display: { md: "flex", sm: "flex", xs: "flex" },
                    height: { md: 100, sm: 50, xs: 35 },
                  }}
                  component="img"
                  alt="aviation"
                  height="150"
                  image={service.bgImage_url}
                  style={{ objectFit: "cover" }}
                />
                <CardContent>
                <Typography
                      variant="body2"
                      color="text.secondary"
                      component={"p"}
                    >
                      Topic: {service.topic}
                    </Typography>
                    <Button
                    >
                    <Link to={`/blog/${service.id}`} state={{id : service.id, test:"test"}} style={{ margin: "1rem",
  textDecoration: "none",color:'white'}}>
                        Read the article
                        </Link>
                        </Button>
                </CardContent>
              </Card>

              //   <Card
              //     elevation={16}
              //     color="secondary"
              //     sx={{ maxWidth: 330 }}
              //     key={service.title}
              //     style={{ margin: 15, padding: 15 }}
              //   >
              //     <Typography variant="paragraph" color="text.secondary">
              //       {new Date(service.createdAt).toLocaleString()}
              //     </Typography>
              //     <CardMedia
              //       sx={{
              //         display: { md: "flex", sm: "flex", xs: "flex" },
              //         height: { md: 100, sm: 50, xs: 35 },
              //       }}
              //       component="img"
              //       alt="aviation"
              //       height="100"
              //       image={service.bgImage_url}
              //       style={{ objectFit: "cover" }}
              //     />
              //     <CardContent>
              //       <Typography gutterBottom variant="h6" component="div">
              //         {service.title}
              //       </Typography>
              //       <Typography variant="body2" color="text.secondary">
              //         {service.description}
              //       </Typography>
              //       <Typography variant="paragraph" color="text.secondary">
              //         Section: {service.topic}
              //       </Typography>
              //     </CardContent>
              //   </Card>
            ))}
        </Box>
      </div>
    );
  }
}
