import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
  Dialog,
  Grid,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  DialogContent,
  Ty,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { TextareaAutosize, Input } from "@mui/base";
import UnstyledInputIntroduction from "./components/CustomInput";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw } from "draft-js";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import { CloseOutlined, SendSharp } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./NewArticle.css";
import axios from "axios";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewArticle = ({ isOpen, setIsOpen }) => {
  const _contentState = ContentState.createFromText("Sample content state");
  const raw = convertToRaw(_contentState); // RawDraftContentState JSON
  const [contentState, setContentState] = useState(raw); // ContentState JSON
  const [title, setTitle] = useState("");
  const [youtubeId, setYoutubeId] = useState("");
  const [bgImage_url, setBgImage] = useState("");
  const [error, setError] = useState(null);
  const [topic, setTopic] = useState("cybersecurity");
  const [description, setDescription] = useState("");
  const [snackOpen, setSnackOpen] = React.useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);
  const submitForm = async () => {
    try {
      const article = {
        title,
        youtubeId,
        convertedContent,
        bgImage_url,
        topic,
        description
      };
      const resp = await axios.post(
        `${process.env.REACT_APP_PROXY}/super-admin-create_article`,
        article
      );
      console.log(resp);
      if (resp.data.error) {
        setError("Unable to create the Article");
      } else {
        setIsOpen(false);
      }
    } catch (error) {
      setError("Unable to create the Article");
    }
  };
  console.log(convertedContent);

  const onEditorStateChange = (e) => {
    setContentState(e);
  };
  const onTitleChange = (e) => {
    const data = e.target.value;
    setTitle(data);
  };

  return (
    <Dialog
      fullScreen
      open={isOpen}
      // onClose={handleClose}
      TransitionComponent={Transition}
      onClose={() => setIsOpen(!isOpen)}
    >
      <AppBar sx={{ position: "relative" }} color="primary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setIsOpen(!isOpen)}
            aria-label="close"
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            color="white"
          >
            Add Article
          </Typography>
          <Button
            style={{ color: "white" }}
            variant="outlined"
            // aria-label="account of current user"
            // aria-controls="menu-appbar"
            // aria-haspopup="true"
            onClick={submitForm}
          >
            <SendSharp style={{ marginRight: 10 }} /> Send Message
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Container>
          {/* <Grid> */}
          <Typography variant="h6">Title:</Typography>
          <UnstyledInputIntroduction
            aria-label="Article's Title"
            placeholder="Article's Title here"
            key={"title"}
            onChange={(e) => onTitleChange(e)}
          />
          <Typography variant="h6">Description:</Typography>
          <UnstyledInputIntroduction
            aria-label="Article's Description"
            placeholder="Article's Description here"
            key={"description"}
            onChange={(e) =>
              setDescription(e.target.value.trim())}
          />
          <br />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Topic</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={topic}
              label="Topic"
              onChange={(e) =>
                setTopic(e.target.value.trim().replace(/\s/g, ""))
              }
            >
              <MenuItem value={"cybersecurity"}>CyberSecurity</MenuItem>
              <MenuItem value={"aviation"}>Aviation</MenuItem>
              <MenuItem value={"meteorology"}>Meteorology</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="h6">BG Image Url:</Typography>
          <UnstyledInputIntroduction
            aria-label="BG Image Url"
            placeholder="BG Image Url"
            key={"url"}
            type="url"
            onChange={(e) =>
              setBgImage(e.target.value.trim().replace(/\s/g, ""))
            }
          />
          <Typography variant="h6">Youtube Id:</Typography>
          <UnstyledInputIntroduction
            aria-label="YoutubeID"
            placeholder="YoutubeID"
            key={"youtube"}
            onChange={(e) =>
              setYoutubeId(e.target.value.trim().replace(/\s/g, ""))
            }
          />
          {/* </Grid> */}
          <Typography variant="h6">Content:</Typography>
          <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </Container>{" "}
      </DialogContent>
      {error && (
        <Snackbar
          open={error.length}
          autoHideDuration={6000}
          onClose={() => {
            setError(null);
          }}
        >
          <Alert
            onClose={() => {
              setError(null);
            }}
            severity={"error"}
            sx={{ width: "100%" }}
          >
            An error occurred! Try again or reach out to us at
            info@davideraroconsulting.com
          </Alert>
        </Snackbar>
      )}
    </Dialog>
  );
};

export default NewArticle;
