import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ScrollToTop from './components/modules/ScrollToTop';
import { Provider } from 'react-redux'
import store from './redux/store'

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//     <BrowserRouter>
//     <App />
    
//     </BrowserRouter>
//     </Provider>
//   </React.StrictMode>
// );

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
    <App />
    
    </BrowserRouter>
    </Provider>
  </React.StrictMode>, rootElement);
} else {
  ReactDOM.render(<React.StrictMode>
    <Provider store={store}>
    <BrowserRouter>
    <App />
    
    </BrowserRouter>
    </Provider>
  </React.StrictMode>, rootElement);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
