import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
const domains = [
  {
    title: "Aviation",
    subtitle:
      "We provide expert solutions to optimize flight operations, ensure airspace safety, and enhance operational efficiency",
    buttonText: "See More",
    ref: "/aviation",
    image:
      "https://images.unsplash.com/photo-1560442018-e2446bd9a812?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1775&q=80",
  },
  {
    title: "Meteorology",
    subtitle:
      "We deliver reliable services and products to increase forecasting to support informed decision-making for weather-sensitive industries",
    buttonText: "See More",
    image:
      "https://images.unsplash.com/photo-1454789476662-53eb23ba5907?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=904&q=80",
    ref: "/meteorology",
  },
  {
    title: "Cyber Security",
    subtitle:
      "Our cybersecurity expertise safeguards critical systems and data, mitigating risks and fortifying digital defenses against evolving threats",
    buttonText: "See More",
    image:
      "https://images.unsplash.com/photo-1614064548016-0b5c13ca2c85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80",
    ref: "/cybersecurity",
  },
];

class Domains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "100%",
          textAlign: "center",
          margin: 10,
          padding: 10,
        }}
      >
        <Typography
          variant="h2"
          sx={{ typography: { md: "h2", sm: "h3", xs: "h4" } }}
        >
          Our Domains
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            flexWrap: "wrap",
          }}
        >
          {domains.map((domain) => (
            <Card
              elevation={16}
              color="primary"
              sx={{ maxWidth: 330 }}
              key={domain.title}
              style={{ margin: 10, padding: 10, borderRadius: "10%" }}
            >
              <CardMedia
                sx={{ borderRadius: "10%" }}
                component="img"
                alt="aviation"
                height="200"
                image={domain.image}
                style={{ objectFit: "fill" }}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {domain.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {domain.subtitle}
                </Typography>
              </CardContent>
              <CardActions>
                <Link to={domain.ref}>
                  <Button size="small">Learn More</Button>
                </Link>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}
export default Domains;
