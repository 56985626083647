import { Typography } from "@mui/material";
import React from "react";
import Slider from "../modules/Slider";
import Domains from "../modules/Domains";
import StyleSubtitle from "../modules/styledSubtitles";
import AboutUs from "../modules/AboutUs";
// import { useLocation } from "react-router-dom";

// axios.defaults.headers.common['fromPage'] = useLocation()
//   if (!this.state.analytics) {

//   }
const subtitles = ["Aviation.", "Weather.", "Cyber Security.", "Solutions."];
const sliders = [
  "https://images.unsplash.com/photo-1583202075405-8d68e5ba459b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
  "https://images.unsplash.com/photo-1566996694954-90b052c413c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80",
  "https://images.unsplash.com/photo-1618060932014-4deda4932554?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80",
  "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80",
];
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subtitle: "Aviation.",
      image:
        "https://images.unsplash.com/photo-1583202075405-8d68e5ba459b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80",
      x: 0,
    };
  }
  componentDidMount() {
    this.changeSubtitle();
  }
  componentWillUnmount() {
    clearInterval(this.changeSubtitle());
  }
  changeSubtitle() {
    let {x} = this.state
    setInterval(() => {
      if (x < subtitles.length) {
        this.setState({ x: x });
        this.setState({
          subtitle: subtitles[x],
          image: sliders[x],
        });
        this.setState({ x: x++ });
      } else if (x === subtitles.length) {
        this.setState({ x: 0 });
        this.setState({ subtitle: "Aviation.", image: sliders[0] });
      } else {
        this.setState({ x: 0 });
        this.setState({ subtitle: "Aviation.", image: sliders[x] });
      }
    }, 4000);
  }

  render() {
    // const subs = ()=>{
    //     setInterval(() => this.changeSubtitle(), 5000)
    // }

    // this.changeSubtitle();
    return (
      <div>
        <Slider images={this.state.image}>
          <div
            style={{
              backgroundColor: "rgba(0,0,0,0, 0.8)",
              // top: 0,
              // left: 0,
              // width: "100%",
              // height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                backgroundColor: "rgba(0,0,0,.5)",
                justifyContent: "center",
              }}
            >
              {/* <div style={{ width: "50%" }}></div> */}
              <div
                style={{
                  margin: 10,
                  padding: 10,
                  minHeight: "97vh",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                <Typography
                  sx={{ typography: { md: "h2", sm: "h3", xs: "h3" } }}
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  DR Consulting
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    textDecoration: "italic",
                    // "@media only screen and (maxWidth: 500px)": {
                    //   fontSize: 20,
                    // },
                  }}
                >
                  From Clouds to Firewalls: Delivering ATC/ATM/AIM and
                  Cybersecurity Excellence
                </Typography>
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <div
                  //     style={{
                  //     display: "flex",
                  //     // flexDirection: "row",
                  //     flexGrow: 1,
                  //     // backgroundColor: "rgba(0,0,0,.5)",
                  //     justifyContent:'center'
                  //   }}
                  >
                    <StyleSubtitle style={{ color: "white" }}>
                      {this.state.subtitle}
                    </StyleSubtitle>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
        <Domains />
        <AboutUs />
      </div>
    );
  }
}
