import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Slider from "../modules/Slider";
import { Container, Skeleton, Typography } from "@mui/material";
import parse from 'html-react-parser'
import YouTube from 'react-youtube';

const BlogArticle = () => {
    let {state} = useLocation()
    const [article,setArticle] = useState(null)
    const [error,setError] = useState(null)
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_PROXY}/blog-articles/${state.id}`)
        .then(resp => setArticle(resp.data))
        .catch(err => setError(true))
      }, []);
    const opts = {
    height: '390',
    width: '640',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
    },
    }
    const _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
      }
    return (
        <div>
           { article && !error ?
           <>
            <Slider images={article.bgImage_url} height={'50vh'}>
                <div style={{
                    height:'100%',
                    width:'100%',
                    background:'rgba(0,0,0,.4)',
                    alignContent:'center',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                <Typography variant="h3"
                sx={{ typography: { md: "h2", sm: "h3", xs: "h3" } }}
                style={{
                  color: "white",
                  textDecoration: "italic",
                  // "@media only screen and (maxWidth: 500px)": {
                  //   fontSize: 20,
                  // },
                }}
                >{article.title}
                
                </Typography>
                </div>
            </Slider>
            <Container>
                {parse(article.convertedContent)}
                {article.youtubeId && <div style={{display:'flex',justifyContent:'center'}}> <YouTube videoId={article.youtubeId} opts={opts} onReady={_onReady} /></div>}

            </Container>
            </>:
            <Skeleton
            animation="wave"
            height={"100vh"}
            width="100vw"
            style={{ marginBottom: 6 }}
          />
            }
            {error&& <div>Error</div>}
        </div>
    )

}

export default BlogArticle