import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
// import { Container } from "@mui/material";
// import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Container, Typography, Box, Button } from "@mui/material";
import AppWidgetSummary from "./widgets/AppWidgetSummary";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import L from 'leaflet';
import TableLog from "./TableLogs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

import dayjs from "dayjs";

const removeDuplicates = (arr) => {
  return [...new Set(arr)];
};

const Dashboard = () => {
  const [logs, setLogs] = useState(null);
  const [users, setUsers] = useState(null);
  const [countries, setCountries] = useState(null);
  const [countriesCount, setCountriesCount] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [device, setNewDevice] = useState(0);
  const [city, setCity] = useState(null);
  const [cityCount, setCityCount] = useState(0);
  const [clicks, setClicks] = useState(0);
  const [from, setFrom] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7)).toISOString()
  );
  const [to, setTo] = useState(new Date().toISOString());
  const { authenticated, user } = useSelector((state) => state.user);
  const navigate = useNavigate()
  const getData = () => {
    setLogs(null)
    setUsers(0);
          setClicks(0);
          setCity([]);
          setCityCount(0);
          setCountries([]);
          setVisitors(0);
          setCountriesCount(0);
          setNewDevice(0);
    axios
        .post(`${process.env.REACT_APP_PROXY}/super-admin-dashboard`, {
          from: from,
          to: to,
        })
        .then((res) => {
          const log = res.data;
          let newArray = [];
          let newVisitors = [];
          let newCountries = [];
          let newDevices = [];
          let newCity = [];
          // Loop for the array elements
          for (let i in log) {
            // Extract the title
            newArray.push(log[i]["id"]);
            newVisitors.push(log[i]["IPv4"]);
            newCountries.push(log[i]["address"]["country"]);
            newCity.push(log[i]["address"]["city"]);
            newDevices.push(log[i]["device"]["device"]["type"]);
          }

          // Display the unique objects
          setLogs(log);

          setUsers(removeDuplicates(newArray).length);
          setClicks(log.length);
          setCity(removeDuplicates(newCity));
          setCityCount(removeDuplicates(newCity).length);
          setCountries(removeDuplicates(newCountries));
          setVisitors(removeDuplicates(newVisitors).length);
          setCountriesCount(removeDuplicates(newCountries).length);
          setNewDevice(removeDuplicates(newDevices).length);
        });
  }
  useEffect(() => {
    if (authenticated && !logs) {
      getData()
    }
  }, []);
  if (!localStorage.DRToken || !authenticated) {
    navigate('/login_super-admin')
  }

  return (
    <>
      <br />
      <Container maxWidth="xl">
        <Grid  style={{display:'flex',justifyContent:"space-around", textAlign:'center', alignItems:'center', margin:20}} >
        <Typography variant="p" style={{margin:10}}> Select the date/time range</Typography>

        {/* <DemoContainer components={['DateTimePicker', 'DateTimePicker']}> */}
        
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="From"
              value={dayjs(from)}
              style={{margin:10}}
              onChange={(newValue) => setFrom(newValue.toISOString())}
            />
            <DateTimePicker
              label="To"
              value={dayjs(to)}
              style={{margin:10}}
              onChange={(newValue) => setFrom(newValue.toISOString())}
            />
          </LocalizationProvider>
          <Button variant="contained" onClick={getData}>Search</Button>
          {/* </DemoContainer> */}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Weekly Visits"
              total={users ? users : 0}
              icon={"fa:users"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Unique Visitors"
              total={visitors ? visitors : 0}
              icon={"fa:users"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Weekly Countries"
              total={countriesCount}
              color="info"
              icon={"bx:world"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Devices "
              total={device}
              color="info"
              icon={"mdi:devices"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Clicks "
              total={clicks}
              color="info"
              icon={"material-symbols:ads-click"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <AppWidgetSummary
              title="Cities "
              total={cityCount}
              color="info"
              icon={"solar:city-bold-duotone"}
            />{" "}
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            style={{
              minHeight: "400px",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Map</Typography>
            <MapContainer
              center={[20, 0]}
              zoom={2}
              scrollWheelZoom={true}
              style={{ height: "100%", width: "100%" }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {logs &&
                logs.map((log) => (
                  <Marker position={[log.latitude, log.longitude]}>
                    <Popup>{log.IPv4}</Popup>
                  </Marker>
                ))}
            </MapContainer>
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            lg={6}
            style={{
              minHeight: "400px",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Logs</Typography>

            {logs && <TableLog rows={logs ? logs : []} renew={() => getData()} />}
            {/* <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            /> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Dashboard;

// i
// // components
// import Iconify from '../components/iconify';
// // sections
// import {
//   AppTasks,
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppWebsiteVisits,
//   AppTrafficBySite,
//   AppWidgetSummary,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';

// // ----------------------------------------------------------------------

// export default function DashboardAppPage() {
//   const theme = useTheme();
