import { configureStore } from '@reduxjs/toolkit'
import homeReducer from './reducers/home'
import webReducer from './reducers/pages'
import userReducer from './reducers/users'
import userMenuReducer from './reducers/userMenu'

export default configureStore({
  reducer: {
    homePage: homeReducer,
    webPages:webReducer,
    user:userReducer,
    userMenu:userMenuReducer,
  },
})